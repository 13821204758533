<template>
  <TransListLayout
    backRouteName="ChildDetail"
    :backRouteQuery="backRouteQuery"
    :backPage="$route.query.backPage"
    :backPageData="{
      year_month : backRouteQuery.transactionYearMonth,
      type : backRouteQuery.transTypeValue,
    }"
    :model="viewModel.model"
    :isOptionSelectPopup="viewModel.isOptionSelectPopup"
    :emptyDesc="viewModel.emptyDesc"
    @onSearch="value => viewModel.onSearch(value)"
    @onClickDetail="transaction_uid => viewModel.onClickDetail(transaction_uid)"
    @onClickOptionSelectPopupClose="viewModel.onClickOptionSelectPopupClose()"
    @onClickOptionSelectPopupCompelete="(transTypeValue, categoryValue) => viewModel.onClickOptionSelectPopupCompelete(transTypeValue, categoryValue)">
    <template v-slot:filter_option>
      <Selectbox
        size="small"
        :title="$poData.trans_filter_month_optionsheet_title"
        :isCloseBtn="true"
        :dataList="viewModel.model.year_month_list"
        :value.sync="viewModel.model.searchData.option.transactionYearMonth"
        @update:value="viewModel.onSearch()" />
      <SelectboxCustom
        size="small"
        title="옵션 선택"
        :value.sync="viewModel.model.searchData.searchOptionText"
        @onClickSelectbox="viewModel.onClickOptionSelectPopup()"
        @update:value="viewModel.onSearch()" />
    </template>
  </TransListLayout>
</template>

<script>
import TransListLayout from '@/views/transaction/view/TransListLayout';
import Selectbox from '@/components/common/selectbox/Selectbox';
import SelectboxCustom from '@/components/common/selectbox/SelectboxCustom';
import { convertIdToText } from "@/utils/selectDataUtils";

// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';
// viewModel
import TransListViewModel from '@/views/transaction/viewModel/TransListViewModel'

export default {
  name: 'ParentsTransListChild',
  mixins:[ InterFaceMixin, BackKeyEventMixin ],
  components:{
    TransListLayout,
    Selectbox,
    SelectboxCustom,
  },
  beforeMount(){
    this.viewModel.model.userType = 'PARENTS_CHILD';
    const query = this.$route.query;
    this.viewModel.init(query);
  },
  computed:{
    backRouteQuery(){
      let result = { childUid : this.viewModel.childUid };
      if(this.viewModel.model.uppperTabValue){
        result.transTypeValue =  this.viewModel.model.uppperTabValue;
      }
      if(this.viewModel.model.searchData.option.transactionYearMonth){
        result.transactionYearMonth =  this.viewModel.model.searchData.option.transactionYearMonth;
      }
      return result
    }
  },
  data(){
    return{
      viewModel: new TransListViewModel(),
    }
  },
  methods:{
    convertIdToText(value, dataListName, cutLength){
      return convertIdToText(value, dataListName, cutLength)
    },
    onClickBackKey(){
      if(this.viewModel.model.searchData.isSearchOpen){
        this.viewModel.onSearchCancle();
      }else if(this.$route.query.backPage){
        this.pageMove(this.$route.query.backPage);
      }else{
        this.$router.push(
          { 
            name: 'ChildDetail',
            query: { 'childUid' : this.viewModel.childUid }
          }
        );
      }
    }
  }
}
</script>